import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

export default function MaxWidthWrapper({ children }: { children: ReactNode }) {
	return (
		<Box position="relative" width="calc(100% - 12vw)" maxW="1400px" marginInline="auto">
			{children}
		</Box>
	);
}
