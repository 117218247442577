import React from 'react';
import { Link } from '@chakra-ui/react';

interface SolidBtnProps {
	content: React.ReactNode;
	href?: string;
	isSubmit?: boolean;
	isEnd?: boolean;
	rounded?: string | { base?: string; sm?: string };
	border?: string | { base?: string; sm?: string };
	width?: string | { base?: string; sm?: string };
}

function SolidBtn({ content, href, isSubmit = false, isEnd = false, rounded, border, width }: SolidBtnProps) {
	return (
		<Link
			type={isSubmit ? 'submit' : 'button'}
			alignSelf={isEnd ? 'flex-end' : 'start'}
			display="flex"
			alignItems="center"
			justifyContent="center"
			w={!width ? 'auto' : width}
			href={href}
			borderRadius={!rounded ? '8px' : rounded}
			h="53px"
			px="24px"
			lineHeight="115%"
			color="neutralLight"
			transition="all 0.2s ease"
			fontFamily="gabarito"
			fontSize="18px"
			fontWeight="semibold"
			bg="primaryDark.100"
			border={!border ? '0px' : border}
			borderColor="whiteAlpha.400"
			_hover={{ bg: 'primaryDark.200', borderColor: 'primaryDark.200', textDecoration: 'none' }}
		>
			{content}
		</Link>
	);
}

export default SolidBtn;
