'use client';

import React from 'react';
import { usePathname } from 'next/navigation';
import { Box, Flex, Stack, useDisclosure, Image, useBreakpointValue, VStack } from '@chakra-ui/react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { IoClose } from 'react-icons/io5';
import { FiUser } from 'react-icons/fi';
import { useUser } from '@/supabase/AuthContext';

import Footer from '@/components/nav/Footer';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';
import { Button } from '@/components/ui/button';
import NavUserPopover from './NavUserPopover';
import SolidBtn from '../landing/buttons/SolidBtn';
import OutlinedBtn from '../landing/buttons/OutlinedBtn';

interface NavItem {
	label: string;
	href?: string;
	onToggle?: () => void;
}

const NAV_ITEMS: Array<NavItem> = [
	{
		label: 'Funkce ',
		href: '/#features',
	},
	{
		label: 'Ceník',
		href: '/#prices',
	},
	{
		label: 'O nás',
		href: '/#about-us',
	},
];

function DesktopNav() {
	const user = useUser();

	const handleScroll = (id: string) => {
		const section = document.getElementById(id);
		if (section) {
			const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
			const offset = window.innerHeight * 0.1;
			window.scrollTo({ top: sectionPosition - offset, behavior: 'smooth' });
		}
	};

	return (
		<Stack
			w="full"
			direction="row"
			align="center"
			justifyContent="space-between"
			gap={8}
			display={{ base: 'none', xl: 'flex' }}
		>
			<Flex gap={8} ml={16}>
				{NAV_ITEMS.map((navItem) => (
					<Button
						// as={Link}
						onClick={() => handleScroll(navItem.href?.slice(2) || '')} // Removing the "/#" prefix
						// href={navItem.href}
						variant="ghost"
						size="lg"
						color="neutralDark"
						_hover={{ bg: 'rgba(234, 248, 243, 0.08)' }}
						key={navItem.label}
					>
						{navItem.label}
					</Button>
				))}
			</Flex>

			<Flex gap={8}>
				<SolidBtn content="Kontaktujte nás" />
				{!user?.email && <OutlinedBtn text="Přihlásit se" href="/login" icon={<FiUser />} />}
				{user?.email && <NavUserPopover isApp={false} />}
			</Flex>
		</Stack>
	);
}

function MobileNavItem({ label, href, onToggle }: NavItem) {
	const handleScroll = () => {
		if (onToggle) {
			onToggle();
		}

		const section = document.getElementById(href?.slice(2) || ''); // Removing the "/#" prefix
		if (section) {
			setTimeout(() => {
				const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
				const offset = window.innerHeight * 0.1;
				window.scrollTo({ top: sectionPosition - offset, behavior: 'smooth' });
			}, 0);
		}
	};

	return (
		<Button
			// as={Link}
			onClick={handleScroll}
			variant="ghost"
			size="lg"
			w="100%"
			h={{ base: '46px', md: '53px' }}
			color="neutralLight"
			_hover={{ bg: 'rgba(234, 248, 243, 0.08)' }}
			key={label}
			// href={href}
		>
			{label}
		</Button>
	);
}

function MobileNav({ onToggle }: { onToggle: () => void }) {
	const user = useUser();

	return (
		<Box
			top={0}
			left={0}
			w="100%"
			h="100vh"
			position="fixed"
			display={{ base: 'inlilneBlock', xl: 'none' }}
			bgColor="neutralDark"
			overflow="hidden"
			zIndex={50}
		>
			<Box
				_before={{
					content: '""',
					display: 'inline-block',
					position: 'absolute',
					top: '-34%',
					left: '0',
					w: '634px',
					h: '634px',
					bgColor: 'primaryDark.100',
					borderRadius: '100%',
				}}
			/>
			<Flex
				position="fixed"
				w="100%"
				h="100vh"
				p={4}
				display={{ base: 'flex', xl: 'none' }}
				flexDirection="column"
				gap={6}
				alignItems="center"
				justifyContent="center"
				bgColor="rgb(10, 15, 13, 0.75)"
				backdropFilter="blur(200px)"
			>
				<VStack w={{ base: '250px', md: '300px' }} gap={4} align="center">
					{NAV_ITEMS.map((navItem) => (
						<MobileNavItem key={navItem.label} {...navItem} onToggle={onToggle} />
					))}
				</VStack>

				{!user?.email ? (
					<>
						<Button
							onClick={onToggle}
							border="1px"
							px={8}
							w={{ base: '250px', md: '300px' }}
							h="53px"
							borderRadius="lg"
							borderColor="primaryDark.100"
							textStyle="lg"
							fontWeight="bold"
							bg="primaryDark.100"
							color="neutralLight"
							_hover={{ bg: 'primaryDark.200', borderColor: 'primaryDark.200' }}
						>
							Kontaktujte nás
						</Button>
						<Button
							onClick={onToggle}
							border="1px"
							px={8}
							w={{ base: '250px', md: '300px' }}
							h="53px"
							borderRadius="lg"
							textStyle="lg"
							fontWeight="bold"
							bg="transparent"
							borderColor="neutralLight"
							color="neutralLight"
							transition="all 0.2s ease"
							_hover={{ bg: 'neutralLight', color: 'neutralDark' }}
						>
							<FiUser />
							Přihlásit se
						</Button>
					</>
				) : null}
				{user?.email ? <NavUserPopover isApp={false} /> : null}
			</Flex>
		</Box>
	);
}

export default function WithSubnavigation({ children }: { children: React.ReactNode }) {
	const pathname = usePathname();
	const { open, onToggle } = useDisclosure();
	const [headerColor, setHeaderColor] = React.useState('rgb(10, 15, 13, 0.8)');

	const variant =
		useBreakpointValue(
			{
				base: 912,
				md: 700,
			},
			{
				fallback: 'md',
			},
		) ?? 0;

	const [scroll, setScroll] = React.useState(false);

	React.useEffect(() => {
		if (pathname === '/') {
			if (scroll && !open) {
				setHeaderColor('transparent');
			} else {
				setHeaderColor('transparent');
			}
		} else if (open) {
			setHeaderColor('transparent');
		} else {
			setHeaderColor('transparent');
		}

		// Function to handle scroll event
		const onScroll = () => {
			if (typeof window !== 'undefined') {
				// Check if the window has been scrolled beyond a certain variant value
				setScroll(window.scrollY > variant);
			}
		};

		// Add scroll event listener
		window.addEventListener('scroll', onScroll);

		// Clean up by removing scroll event listener when component is unmounted
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [variant, pathname, scroll, open]);

	return (
		<>
			<Box minH="100vh" position="relative">
				<Flex
					width="100%"
					height={{ base: '80px', md: '96px' }}
					position="fixed"
					top={0}
					bgColor={headerColor}
					zIndex={100}
					backdropFilter="blur(32px)"
					alignItems="center"
				>
					<MaxWidthWrapper>
						<Flex justifyContent="space-between" align="center">
							<a href="/">
								<Image
									h={{ base: '30px', md: '36.95px' }}
									src="/images/taskgrip_horizontalni_svetle_gradient.png"
									alt="TaskGrip Logo"
									objectFit="contain"
								/>
							</a>

							<Button
								onClick={onToggle}
								variant="ghost"
								aria-label="Toggle Navigation"
								display={{ xl: 'none' }}
								_active={{ bg: 'transparent' }}
								_focus={{ bg: 'transparent' }}
							>
								{open ? (
									<IoClose color="neutralLight" width={4} height={4} />
								) : (
									<RxHamburgerMenu color="neutralLight" width={6} height={6} />
								)}
							</Button>

							<DesktopNav />
						</Flex>
					</MaxWidthWrapper>
				</Flex>
				{open && <MobileNav onToggle={onToggle} />}
				{children}
			</Box>
			<Footer />
		</>
	);
}
