import React from 'react';
import { Link } from '@chakra-ui/react';

interface OutlinedBtnProps {
	text: string;
	href?: string;
	// eslint-disable-next-line react/no-unused-prop-types
	icon?: React.ReactElement;
	width?: string | { base?: string; sm?: string };
}

function OutlinedBtn({ text, href, icon, width }: OutlinedBtnProps) {
	return (
		<Link
			as={Link}
			href={href}
			height="auto"
			border="1px"
			width={!width ? 'auto' : width}
			display="flex"
			alignItems="center"
			justifyContent="center"
			h="53px"
			px={6}
			borderRadius="lg"
			textStyle="lg"
			fontWeight="semibold"
			bg="white"
			borderColor="neutralLight"
			color="black"
			transition="all 0.2s ease"
			_hover={{ bg: 'neutralLight', color: 'neutralDark', textDecoration: 'none' }}
			style={{ margin: '0px' }}
			flex={1}
			minW="fit-content"
		>
			{icon}
			{text}
		</Link>
	);
}

export default OutlinedBtn;
